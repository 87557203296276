<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
            id="mailTopicGrid"
            ref="mailTopicGridRef"
            :data-source="dataSource"
            :height="setHeight"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :hover-state-enabled="true"
            :row-alternation-enabled="false"
            @initialized="onInitialized"
            @content-ready="onContentReady"
            @toolbar-preparing="onToolbarPreparing"
            @editor-preparing="onEditorPreparing"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-filter-row :visible="true" />
          <dx-column data-field="description" caption="Description" />
          <dx-column data-field="emailTemplate" caption="Mail Template" />
          <dx-column data-field="from" caption="From" />
          <dx-column data-field="developmentTo" caption="Development To" />
          <dx-column data-field="subjectSample" caption="Subject Sample" />
          <dx-column data-field="contentSample" caption="Content Sample" />
          <dx-column data-field="isPlainText" caption="Is Mail Plain Text" />
          <dx-column data-field="isActive" caption="Is Mail Active" />
          <dx-column data-field="logEnabled" caption="Log Enabled" />
          <dx-editing
              :allow-deleting="true"
              :use-icons="true"
          />
          <dx-column type="buttons" caption="Actions">
            <dx-button ref="btnEdit" hint="Edit" icon="edit" :on-click="onCreateOrUpdate" />
            <dx-button name="delete" />
          </dx-column>
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
    <!--Begin:: Create Or Update Form-->
    <dx-util-popup
        :visible="popupVisible"
        :show-close-button="false"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :width="720"
        :height="640"
        title="Mail Topic Info"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
      <form @submit="handleSubmit">
        <dx-util-form ref="mailTopicFormRef" :form-data="mailTopic" :col-count="1" :show-colon-after-label="true" label-location="top">
          <dx-util-group-item :col-count="1">
            <dx-util-item data-field="description" />
          </dx-util-group-item>

          <dx-util-group-item :col-count="2">
            <dx-util-item data-field="emailTemplate" editor-type="dxSelectBox" :editor-options="templateOptions">
<!--              <dx-util-validator>-->
<!--                <dx-util-required-rule message="Template is required" />-->
<!--              </dx-util-validator>-->
            </dx-util-item>
            <dx-util-item data-field="subjectSample" caption="Subject Sample" />
          </dx-util-group-item>
          <dx-util-group-item :col-count="2">
            <dx-util-item data-field="from" caption="From" />
            <dx-util-item data-field="developmentTo" caption="Development To" />
          </dx-util-group-item>
          <dx-util-group-item :col-count="1">
            <dx-util-item data-field="contentSample" :editor-options="{ height: 200 }" editor-type="dxTextArea" />
          </dx-util-group-item>
          <dx-util-group-item :col-count="3">
            <dx-util-item data-field="logEnabled" editor-type="dxCheckBox" />
            <dx-util-item data-field="isActive" editor-type="dxCheckBox" />
            <dx-util-item data-field="isPlainText" editor-type="dxCheckBox" />
          </dx-util-group-item>
        </dx-util-form>
      </form>
    </dx-util-popup>
    <!--End:: -->
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import mailTopicService from '@/http/requests/mail/mailTopicService'
import mailTemplateService from '@/http/requests/mail/mailTemplateService'
import { dataSource as topicDataStore } from './mailTopicStore'

export default {
  mixins: [GridBase],
  data: () => ({
    popupVisible: false,
    dataSource: topicDataStore,
    asinOptions: {
      readOnly: true,
    },
    mailTopic: {
      id: null,
      description: '',
      emailTemplate: null,
      from: '',
      developmentTo: '',
      subjectSample: '',
      contentSample: '',
      isPlainText: false,
      isActive: false,
      logEnabled: false,
    },
    mailTopicDefault: {},
    templates: [],
    manufacturers: [],
    accountNo: '',
    selectedPackType: 'all',
    query: '',
  }),
  computed: {
    templateOptions() {
      return {
        dataSource: this.templates,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        dropDownOptions: {
          height: 150,
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.mailTopic = { ...this.mailTopicDefault }
          this.popupVisible = false
        },
      }
    },
  },
  mounted() {
    this.getTemplateList()
    this.mailTopicDefault = { ...this.mailTopic }
  },
  methods: {
    onChangePackType(e) {
      this.selectedPackType = e.value
      this.searchByFilter()
    },
    getProductByAccountNo(e) {
      if (e.event.keyCode !== 13) return
      this.accountNo = e.event.target.value
      this.searchByFilter()
    },
    getTemplateList() {
      this.templates.splice(0, this.templates.length)
      mailTemplateService.findAllTemplateTopic().then(result => {
        const { data } = result
        data.forEach(element => {
          this.templates.push({ id: element.name, name: element.caption })
        })
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      // toolbarItems.unshift({
      //   location: 'before',
      //   template: 'filterToolbar',
      // })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.popupVisible = true
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reloadGrid()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      const that = this
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          that.query = arg.component.option('value')
        }
        e.editorOptions.onEnterKey = arg => {
          e.component.searchByText('')
          that.query = arg.component.option('value')
          that.searchByFilter()
        }
      }
    },
    onCreateOrUpdate(e) {
      const id = e.row.data.id
      if (id !== undefined && id !== null && id !== '' && id !== 0) {
        mailTopicService.fetchById(id).then(result => {
          this.mailTopic = { ...result.data }
          this.popupVisible = true
        })
      } else {
        this.popupVisible = true
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      const store = this.dataSource.store()
      if (this.mailTopic.id !== undefined && this.mailTopic.id !== null && this.mailTopic.id !== '' && this.mailTopic.id !== 0) {
        store.update(this.mailTopic.id, this.mailTopic).then(result => {
          if (result.id !== undefined && result.id !== null && result.id !== '' && result.id !== 0) {
            this.popupVisible = false
            this.mailTopic = { ...this.mailTopicDefault }
          }
        })
      } else {
        store.insert(this.mailTopic).then(() => {
          this.popupVisible = false
          this.mailTopic = { ...this.mailTopicDefault }
        })
      }
      this.reloadGrid()
    },
    async searchByFilter() {
      const dataGrid = this.$refs.mailTopicGridRef.instance
      const store = this.dataSource.store()
      await store.load(this.getSearchFilter()).then(result => {
        dataGrid.option('dataSource', result.data)
      })
    },
    getSearchFilter() {
      const take = 50
      const skip = 0
      const loadOptions = { take, skip, ...this.dataSource.loadOptions() }
      // const arr = [
      //   ['tenantId', '=', this.accountNo],
      //   ['packType', '=', this.selectedPackType],
      //   ['q', '=', this.query],
      // ]
      const userData = {
        filter: [],
      }
      const options = { userData, ...loadOptions }
      return options
    },
    reloadGrid() {
      const dataGrid = this.$refs.mailTopicGridRef.instance
      dataGrid.option('dataSource', this.dataSource)
    },
  },
}
</script>

<style lang="scss">
</style>
