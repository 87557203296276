import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fethAllTemplates() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.emailController.findAllTemplate())
        .then(response => resolve({
          data: response.data.body,
        }))
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async findAllTemplateTopic() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.emailController.findAllTemplateTopic())
        .then(response => resolve({
          data: response.data.body,
        }))
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Items
  async findHtmlContentByEmailVariable(emailVariable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.emailController.findHtmlContentByEmailVariable(), emailVariable)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async sendEmailByEmailVariableTemplate(emailVariable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.system.emailController.sendEmailByEmailVariableTemplate(), emailVariable)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getUserTenants(userId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.getUserTenants(userId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.tenantController.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateItem(userId, tenantId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.updateUserTenant(userId, tenantId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async deleteItemById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.system.tenantController.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
